@tailwind base;
@tailwind components;
@tailwind utilities;

/* Used for the background effect in the landing page */
.background {
  background-image: radial-gradient(
    circle,
    rgba(58, 58, 58, 0.75) 2px,
    transparent 0px
  );
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

.lower-gradient {
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0)
  );
  height: 20vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.fading-hr {
  position: relative;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3),
    transparent
  );
}

/* Used for code / table formatting within messages */
pre {
  @apply overflow-auto rounded-lg;
}

.window-heights {
  @apply h-[14em] overflow-y-auto overflow-x-hidden sm-h:h-[17em] md-h:h-[22em] lg-h:h-[30em];
}

.link {
  @apply cursor-pointer text-sky-500 underline hover:text-sky-300;
}

table {
  @apply w-full rounded-lg text-white;
  background-color: #0d1117;
}

th,
td {
  @apply rounded-lg border border-gray-700 px-4 py-2;
}

th {
  background-color: #161b22;
}

tr:nth-child(even) {
  background-color: #1c2028;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
/* total width */
div::-webkit-scrollbar {
  border-radius: 16px;
  background-color: #464649;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
div::-webkit-scrollbar-track {
  border-radius: 16px;
  background-color: #464649;
}

/* scrollbar itself */
div::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 2px solid #464649;
}

/* set button(top and bottom of the scrollbar) */
div::-webkit-scrollbar-button {
  display: none;
}

/*  disappearing animation */
.animation-hide {
  animation: hide 1s cubic-bezier(0.4, 0, 0.2, 1) 0s 1 forwards;
}

@keyframes hide {
  to {
    opacity: 0;
  }
}
